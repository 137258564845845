import {v2Request} from "./v2Request";
import {timeRandomId} from "../../config/util";


export const redirectV2Api = {

  /**
   * 创建一个可跳转地址
   */
  create(url) {
    const id = timeRandomId()
    v2Request.post('redirect/create', {url, id}).then()
    return id
  },


  /**
   * 根据id查找
   */
  async findUrlById(id) {
    return v2Request.get('redirect/findUrlById', {params: {id}})
  }

}
