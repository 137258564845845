<template>
  <div class="home-list home-collections" ref="homeCollection">
    <van-sticky :offset-top="0">
      <home-collection-search ref="homeCollectionSearch" :header-height="headerHeight" :query="query"
                              @search="onSearch"/>
      <van-dropdown-menu active-color="#151d36" id="collectionSticky">
        <van-dropdown-item :value="null" disabled>
          <span slot="title" @click="clearQuery" class="all-collection-btn">全部合集</span>
        </van-dropdown-item>
        <van-dropdown-item v-model="query.categoryId" @change="onChangeQuery" :options="categories"/>
        <van-dropdown-item v-model="query.sortField" @change="onChangeQuery" :options="[
          {text: '默认排序', value: ''},
          {text: '最新严审', value: 'NEW_UPLOAD'},
          {text: '浏览最多', value: 'MORE_BROWSE'},
          {text: '推荐最多', value: 'MORE_RECOMMEND'},
          {text: '收藏最多', value: 'MORE_COLLECT'},
          {text: '随便看看', value: 'RANDOM_BROWSE'}
        ]"/>
      </van-dropdown-menu>
      <div class="keywords" v-if="(query.keyword && query.keyword.length) || (query.categoryId && selectedCategory)">
        <template v-if="query.keyword && query.keyword.length">
          <div class="keyword" v-for="(item,index) in setTag(query.keyword)" :key="index">
            {{ item }}
            <van-icon name="cross" style="margin-left: 4px" @click="removeKeyword(item)"/>
          </div>
        </template>
        <template v-if="query.categoryId && selectedCategory">
          <div class="keyword">{{ selectedCategory.text }}
            <van-icon name="cross" style="margin-left: 4px" @click="clearCategoryId"/>
          </div>
        </template>
        <div style="margin: 0 0 4px 8px;display: flex;align-items: center;font-size: 12px;flex-shrink: 0"
             @click="clearQuery(true)">
          清空
          <van-icon name="cross" style="margin-left: 4px"/>
        </div>
        <div style="display: flex;align-items: center;padding-left: 4px">
          <img @click="copyLink" style="height: 20px;transform: translateY(-2px)"
               src="https://cdn.001ppt.cn/h5/img/link.svg" alt="">
        </div>
      </div>
    </van-sticky>


    <van-pull-refresh v-model="refreshing" @refresh="loadPageData(1,true,200)" loading-text="灵感加载中">
      <template>
        <div v-if="refreshing" style="min-height: 100vh">
          <div class="skeleton-block"
               style="width: calc(100vw - 27px);height:calc( (100vw - 27px) * 10 /16 );margin: 12px auto 0 auto;border-radius: 6px"></div>
          <div class="skeleton-block"
               style="width: calc(100vw - 27px);height:calc( (100vw - 27px) * 10 /16 );margin: 12px auto 0 auto;border-radius: 6px"></div>
          <div class="skeleton-block"
               style="width: calc(100vw - 27px);height:calc( (100vw - 27px) * 10 /16 );margin: 12px auto 0 auto;border-radius: 6px"></div>
        </div>
        <van-list v-show="pageData.total>0"
                  offset="400"
                  :immediate-check="true"
                  :finished="!hasNextPage"
                  finished-text="灵感到底了~"
                  v-model="loading"
                  @load="loadPageData(pageData.current+1,false)">
          <div class="case-container">
            <div class="home-collection-item" v-for="(item,index) in pageData.records" :key="index">
              <v-case group stopTagClick @clickTag="setKeyword" @handle-click="goDetail(item)" :detail="item"
                      search></v-case>
            </div>
          </div>
        </van-list>
      </template>
    </van-pull-refresh>

    <div class="scroll-empty" v-if="pageData.total<=0 && !refreshing">
      <img src="https://cdn.001ppt.cn/h5/assets/images/noData.png" width="150" style="margin-bottom: 20px">
      <div style="font-size: 14px;color: #999;padding-top: 16px">噢！这可能有点儿空 (⊙v⊙)</div>
    </div>

    <div class="pager" v-if="scrolling">
      <div class="pager-curr">
        {{ currCardIndex > 999 ? '999+' : (currCardIndex > pageData.total ? pageData.total : currCardIndex) }}
      </div>
      <div class="pager-total">{{ pageData.total >= 999 ? '999+' : pageData.total }}</div>
    </div>
    <div class="pager" v-else @click="toTop" style="font-size: 20px">
      <van-icon name="back-top"/>
    </div>

  </div>
</template>


------------------ script ------------------
<script>
import {planListCategoryV2Api} from "../../../api/v2/planListCategoryV2Api";
import {scheme, user} from '@/api'
import vCase from '@/components/case';
import {mapGetters, mapMutations} from "vuex";
import {scrollToDropdownMenu} from "./home";
import {copyToClipBoard, objToQueryString, sleep} from "../../../config/util";
import HomeCollectionSearch from "./homeCollectionSearch";
import DropdownCascader from "../../../components/dropdownCascader";
import {redirectV2Api} from "../../../api/v2/redirectV2Api";
import HomeHeader from "./homeHeader.vue";

export default {
  components: {HomeHeader, DropdownCascader, HomeCollectionSearch, vCase},
  props: ['headerHeight', 'currTab'],
  computed: {
    ...mapGetters(['userInfo', 'wallet']),

    // 是否有下一页
    hasNextPage() {
      const {total, current, pageSize} = this.pageData;
      return total > (current * pageSize);
    },
  },

  data() {
    return {
      hasRunInit: false,
      currCardIndex: 1,
      scrolling: false,
      categories: [
        {value: '', text: '不限类别'}
      ],
      query: {
        sortField: '',
        categoryId: '',
        keyword: ''
      },
      pageData: {
        current: 1,
        pageSize: 5,
        records: [],
        total: 0
      },
      refreshing: false,
      loading: false,
      selectedCategory: null
    }
  },


  activated() {
    document.querySelector('.home-collections').scrollTop = this.$store.state.scrollPosition.home.collection

  },


  mounted() {
    this.init()
    document.querySelector('.home-collections').addEventListener('scroll', this.onScroll)
  },


  beforeDestroy() {
    document.querySelector('.home-collections').removeEventListener('scroll', this.onScroll)
  },


  methods: {

    clearCategoryId() {
      this.query.categoryId = ''
      this.onChangeQuery()
    },

    // 初始化，该方法只执行一次
    async init() {
      if (!this.hasRunInit) {

        const {sortField, categoryId, keyword, tab} = this.$route.query


        if (sortField) {
          this.query.sortField = sortField
        }
        if (categoryId) {
          this.query.categoryId = categoryId
        }
        if (keyword) {
          this.query.keyword = keyword
        }


        await this.loadCategories()
        await this.loadPageData(1, true);
        this.computeSelectedCategory()
      }
      this.hasRunInit = true
    },


    // 加载合集分类
    async loadCategories() {
      const res = await planListCategoryV2Api.findAll()
      let categories = res.map((row) => {
        return {
          text: row.name,
          value: row.id
        }
      })
      categories = [{value: '', text: '不限类别'}, ...categories]
      this.categories = categories
    },


    // 清空搜索条件
    clearQuery() {
      this.query.sortField = ''
      this.query.categoryId = ''
      this.query.keyword = ''
      this.onChangeQuery()
    },


    // 选项改变
    async onChangeQuery() {
      this.$refs.homeCollection.scrollTo({top: 0, behavior: 'smooth'})
      this.refreshing = true
      await this.loadPageData(1, true, 600)
      const queryString = objToQueryString({
        ...this.query,
        tab: 'collection'
      })
      history.replaceState(null, null, '/planList?' + queryString)
      this.computeSelectedCategory()
    },


    computeSelectedCategory() {
      if (this.query.categoryId) {
        this.selectedCategory = this.categories.find(c => c.value === this.query.categoryId)
      } else {
        this.selectedCategory = null
      }
    },


    copyLink() {
      let str = ""
      if (this.selectedCategory) {
        str += this.selectedCategory.text + "的"
      }
      if (this.query.keyword) {
        str += `包含${this.query.keyword}的`
      }

      const redirectId = redirectV2Api.create(window.location.href)
      str += '方案合集' + `https://www.001ppt.com/redirect?id=${redirectId}`

      copyToClipBoard(str)
      this.$toast.success('复制成功')
    },


    // 加载数据
    async loadPageData(current, clearRecords = false, minLoading = 0) {
      const loadingProm = sleep(minLoading)
      if (clearRecords) {
        this.refreshing = true
      }
      try {
        const pageSize = this.pageData.pageSize;
        const params = {
          current, pageSize,
          size: pageSize,
          ...this.query
        };
        const res = await scheme.listGroup(params);
        if (res.code !== 1) {
          return;
        }
        this.pageData.current = res.data.current;
        this.pageData.pageSize = res.data.size;
        this.pageData.total = res.data.total;
        if (clearRecords) {
          this.pageData.records = res.data.records;
        } else {
          this.pageData.records = [...this.pageData.records, ...res.data.records];
        }
      } finally {
        await loadingProm
        this.refreshing = false;
        this.loading = false
      }
    },


    // 跳转至详情页
    goDetail(item) {
      const collectionList = document.querySelector('.home-collections');
      if (collectionList) {
        this.$store.commit('scrollPosition/SET_HOME_SCROLL', {collection: collectionList.scrollTop})
      }
      this.$router.push(`/casePlanDetail/${item.id}`)
    },


    onSearch(val) {
      this.query.keyword = val
      this.onChangeQuery()
    },


    toggleSearch() {
      this.$refs.homeCollectionSearch.toggleSearch()
    },


    hideSearch() {
      this.$refs.homeCollectionSearch.hide()
    },


    onScroll() {
      if (!this.scrolling) {
        this.scrolling = true
        setTimeout(() => {
          this.scrolling = false
        }, 1000)
      }
      const bottom = document.querySelector('#collectionSticky').getBoundingClientRect().bottom
      const items = document.getElementsByClassName('home-collection-item')
      let index = 1
      for (const item of items) {
        if (item.getBoundingClientRect().top < bottom) {
          index++
        }
      }
      this.currCardIndex = index
    },


    toTop() {
      document.querySelector('.home-collections').scrollTo({top: 0, behavior: 'smooth'})
    },


    setKeyword(k) {
      this.query.keyword = k
      this.onChangeQuery()
    },


    removeKeyword(k) {
      const keyword = this.query.keyword
      this.setKeyword(keyword.split(',').filter(kw => kw !== k).join(','))
    }

  },

}
</script>


------------------ styles ------------------
<style lang="less">
@import "./home-list";

.home-collections {
  max-height: calc(100vh - @tabbar-height);
}

.home-collections .van-dropdown-item__content {
  position: absolute;
}

</style>
