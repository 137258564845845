<template>
  <model ref="model">
    <div class="confirm">
      <div class="confirm-type" v-if="options.type">
        <van-icon v-if="options.type==='warning'" name="warning" style="font-size: 80px"/>
      </div>
      <div class="confirm-title">{{ options.title }}</div>
<!--      <div class="pxBorder"></div>-->
      <div class="confirm-content">{{ options.content }}</div>
      <div class="confirm-buttons">
        <div class="confirm-btn confirm-btn-ok" @click="confirm">{{ options.confirmText || '确认' }}</div>
        <div class="confirm-btn confirm-btn-cancel" @click="cancel">{{ options.cancelText || '取消' }}</div>
      </div>
    </div>
  </model>
</template>

<script>
import Model from "./model/model";

export default {
  components: {Model},
  data() {
    return {
      options: {
        title: '',
        content: '',
        confirmText: '',
        cancelText: '',
        type: ''
      }
    }
  },


  methods: {
    show(options) {
      this.options = options || {}
      this.$refs.model.show()
    },
    hide() {
      this.$refs.model.hide()
    },


    confirm() {
      this.$emit('confirm')
      this.hide()
    },


    cancel() {
      this.$emit('cancel')
      this.hide()
    }

  }

}
</script>

<style scoped lang="less">
.confirm-title{
  font-size: 20px;
  text-align: center;
  font-weight: bolder;
  color: #151d36;
}

.pxBorder {
  background-color: rgba(21, 29, 54, 0.03);
  width: 100%;
  height: 1px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.confirm-type{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
}



.confirm-content {
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(21, 29, 54, .3);
  padding-top: 16px;
  font-size: 14px;
}

.confirm-buttons{
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-top: 16px;
}

.confirm-btn{
  min-width: 120px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f4f4f4;
  border-radius: 100px;
}

.confirm-btn.confirm-btn-ok{
  background: #151D36;
  color: #fff;
}
</style>
