<template>
  <div class="dropdown-cascader">
    <div class="dropdown-cascader-column">
      <div class="dropdown-cascader-item" v-for="item in options" :key="item[valueKey]" @click="item1=item">
        <div>{{item[labelKey]}}</div>
        <div>

        </div>
      </div>
    </div>
    <div class="dropdown-cascader-column sub-column">
      <div class="dropdown-cascader-item" v-if="item1 && item1[childrenKey]" v-for="item in item1[childrenKey]" :key="item[valueKey]">
        <div>{{item[labelKey]}}</div>
        <div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  props:['labelKey','valueKey','childrenKey','options','value'],
  data(){
    return {
      item1: {},
      item2: {}
    }
  },

  watch:{
    value:{
      immediate: true,
      handler(){
        this.onValueChange()
      }
    },

    options:{
      immediate: true,
      handler(){
        this.onValueChange()
      }
    }
  },


  methods:{
    onValueChange(){

    },
  }

}
</script>

<style scoped>
.dropdown-cascader{
  display: flex;
  align-items: center;
  max-width: 100vw;
  overflow-x: hidden;
}

.dropdown-cascader-column{
  flex-grow: 1;
  flex-shrink: 0;
  height: 40vh;
  max-width: 50%;
  overflow-y: auto;
}

.sub-column{
  border-left: 1px solid #e9e9e9;
}

.dropdown-cascader-item{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
}
</style>
