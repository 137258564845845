<template>
  <div>
    <van-popup v-model="visible" position="top" transition="fade">
      <div class="home-search">
        <div :style="{height:headerHeight+'px'}"></div>
        <van-search @focus="onFocus" ref="searchInput" placeholder="搜合集" v-model="searchValue" @search="onSearch"/>

        <div class="search-history" v-if="historyKeywords && historyKeywords.length">
          <div class="search-history-header">
            <div class="search-history-title">最近搜索</div>
            <div style="display: flex;align-items: center" @click="showDeleteHistoryConfirm">
              <!--              <div class="expand-btn">-->
              <!--                <van-icon name="arrow"></van-icon>-->
              <!--              </div>-->
              <div>
                <van-icon name="delete-o"/>
              </div>
            </div>
          </div>

          <div class="keywords" style="padding: 8px 0">
            <div class="keyword" v-for="item in historyKeywords" :key="item" @click="setKeyword(item)">{{ item }}</div>
          </div>
        </div>
      </div>
    </van-popup>


    <div class="home-search" @click="visible=true">
      <div :style="{height:headerHeight+'px'}"></div>
      <van-search @focus="onFocus" ref="searchInput" placeholder="搜合集" v-model="searchValue" @search="onSearch"/>
    </div>

    <confirm ref="deleteHistoryConfirm" @confirm="deleteHistory"/>
  </div>
</template>

<script>
import {sleep} from "../../../config/util";
import {searchHistoryV2Api} from "../../../api/v2/searchHistoryV2Api";
import Confirm from "../../../components/confirm.vue";

export default {
  components: {Confirm},
  props: ['headerHeight', 'query'],
  watch: {
    'query.keyword': {
      immediate: true,
      handler(val) {
        this.searchValue = val
      }
    },

    async visible() {
      if (this.visible) {
        await sleep(200)
        this.$refs.searchInput.querySelector('input').focus()
      }
    }
  },


  async mounted() {
    const res = await searchHistoryV2Api.findMyHistoryKeywords()
    this.historyKeywords = res.planListKeywords
  },


  data() {
    return {
      visible: false,
      searchValue: '',
      historyKeywords: []
    }
  },


  methods: {
    onSearch() {
      this.searchValue = this.searchValue.trim()
      if (this.searchValue && this.searchValue.length && !this.historyKeywords.includes(this.searchValue)) {
        this.historyKeywords = [this.searchValue, ...this.historyKeywords]
      }
      this.$emit('search', this.searchValue)
      this.visible = false
    },

    setKeyword(keyword) {
      this.searchValue = keyword
      this.onSearch()
    },


    showDeleteHistoryConfirm() {
      this.$refs.deleteHistoryConfirm.show({
        type: 'warning',
        title: '删除搜索记录',
        content: '该操作将删除所有搜索记录，请确认'
      })
    },


    // 删除搜索历史
    async deleteHistory() {
      await searchHistoryV2Api.deleteMy('planList')
      this.historyKeywords = []
      this.$toast.success('删除成功')
    },


    toggleSearch() {
      this.visible = !this.visible
    },

    hide() {
      this.visible = false
    },


    onFocus() {
      this.$refs.searchInput.querySelector('input').select()
    }
  }

}
</script>

<style scoped lang="less">
@import "./home-list";

.home-search {
  background: #fff;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

/* 定义进入前和离开的状态 */
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}


.search-history {
  margin-top: 24px;
}

.search-history-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search-history-title {
  font-weight: bolder;
}

.history-tags {
  padding: 12px 0 !important;
  border-bottom: none !important;
}

.expand-btn {
  transform: rotateZ(90deg);
  transition: transform .2s ease-in;
}


</style>
