<template>
  <div :class="'case-item '+(self?'self':'')">
    <div @click="handleClick($event)">
      <span class='case-tag' :class="setTagClass(detail.planCategory)">{{ detail.planCategory }}</span>
      <div class="cover-div">
        <van-image
          width="100%"
          height="100%"
          :src="cover"
          use-loading-slot
          v-if="!detail.plan || detail.plan.isOnSell"
        >
<!--          <img style="height: 100%;width: 100%"-->
<!--               src="http://lingganyanxuan147.oss-cn-guangzhou.aliyuncs.com/1617952259180c8ac890f391a.png"-->
<!--               slot="loading">-->
          <div class="skeleton-block" style="height: 100%;width: 100%"></div>
        </van-image>
        <div v-else>已失效方案</div>
      </div>
      <div class="title-group">
        <img
          style="height: 24px;margin-right: 4px"
          src="https://cdn.001ppt.cn/pc2/static/imgs/svg/high-quality.svg" alt=""
          v-if="detail.isRecommended || (detail.plan && detail.plan.isRecommended)">
        <span
          class='title-span'
          :class="{'title-span-is-recommended':detail.isRecommended || (detail.plan && detail.plan.isRecommended)}">
          {{
            !group ? detail.planTitle || (detail.plan && detail.plan.planTitle ? detail.plan.planTitle : '') || '装置艺术展 意大利艺术家Edoar ..' : detail.title
          }}
        </span>

      </div>
      <div class='tags' :class="(self || group) && 'group'">
        <template v-for="tag in tags">
          <span class="one-tag" @click="searchByTag(tag)">{{ tag }}</span>
        </template>
      </div>
    </div>
    <div class="data" v-if="!group">
      <div class="dynamic">
        <div class="dynamic-item" v-if="visibleOption.browseTimesVisible">
          <div class="eye-icon">
            <van-icon name="eye-o"/>
          </div>
          <div class="dynamic-item-value">
            {{ fansNumFilter(detail.browseAmount || (detail.plan ? detail.plan.viewTimes : 0) || 0) }}
          </div>
        </div>
        <div class="dynamic-item" @click.stop="toggleCollect">
          <template>
            <i class="iconfont2 icon-star" v-if="!detail.hasCollect"></i>
            <i class="iconfont2 icon-star-active" v-else style="color: #ee4a50;"></i>
          </template>
          <div class="dynamic-item-value"
               v-if="visibleOption.collectTimesVisible && (detail.collectAmount || detail.plan.collectTimes)">
            {{ detail.collectAmount || (detail.plan ? detail.plan.collectTimes : 0) || 0 }}
          </div>
        </div>
        <div class="dynamic-item" v-if="visibleOption.recommendTimesVisible">
          <i class="iconfont icon-like"></i>
          <div class="dynamic-item-value">
            {{ fansNumFilter(detail.recommendAmount || (detail.plan ? detail.plan.recommendTimes : 0) || 0) }}
          </div>
        </div>
      </div>
      <div class="info">
        <template v-if="visibleOption.fileExtVisible">
          {{ fileExtFilter(detail.uploadResourceRemark || detail.plan.fileExt) }}
        </template>
        共{{ detail.uploadResourcePage || detail.plan.pageTotal }}页
      </div>
    </div>

    <div class='creator-group' v-if="!self && !group" @click="goUserHome">
      <avatar class="avatar" :src="avatarUrl"></avatar>
      <!--<img class="avatar" :src="detail.uploadResourceUrl"/>-->
      <span class='content'>{{ nickName }}</span>
      <!--<template v-if="detail.vipIdentify == 1 ">-->
      <img class="vip" v-if="getVipIcon(innerVipRightId)" :src="getVipIcon(innerVipRightId)"
           alt="" width="15">
      <!--</template>-->
      <user-level v-if="(detail && detail.creator && detail.creator.userLevel) || (detail && detail.userLevel)"
                  :level="detail.userLevel || detail.creator.userLevel"
                  style="transform: translateY(-1px) scale(.9); margin-left: 4px"/>
    </div>
  </div>
</template>

<script>
import avatar from './avatar'
import UserLevel from "./userLevel";
import {planV2Api} from "../api/v2/planV2Api";
import {sleep} from "../config/util";

export default {
  name: "case",
  components: {UserLevel, avatar},
  props: {
    stopTagClick: {
      type: Boolean
    },
    group: {
      type: Boolean,
      default: false
    },

    detail: {
      type: Object,
      default: null
    },
    self: {
      type: Boolean,
      default: false
    },
    search: {
      type: Boolean,
      default: false
    }
  },


  data() {
    return {
      visibleOption: {
        browseTimesVisible: false,
        recommendTimesVisible: false,
        collectTimesVisible: false
      }
    }
  },


  computed: {
    cover() {
      if (this.detail.projPlanPicResources && this.detail.projPlanPicResources.length && this.detail.projPlanPicResources[0]) {
        const cover = this.detail.projPlanPicResources[0].picFullPath;
        return this.ossBlur(cover, 50, 50, !this.hasLogin);
      } else if (this.detail.img && this.detail.img.length) {
        return this.ossBlur(this.detail.img, 20, 50, !this.hasLogin)
      } else if (this.detail.plan && this.detail.plan.cover && this.detail.plan.cover.length) {
        return this.ossBlur(this.detail.plan.cover, 20, 50, !this.hasLogin)
      } else {
        return '';
      }
    },


    tags() {
      if (this.group) {
        return (this.detail.tags || '').split(',').slice(0, 3).filter(t => t && t.length);
      }

      if (this.detail && this.detail.plan) {
        const planTagName = this.detail.plan.planTagName || '';
        return planTagName.split(',').slice(0, 3).filter(t => t && t.length);
      }
      return this.detail.planTagName.split(',').slice(0, 3).filter(t => t && t.length);
    },


    avatarUrl() {
      const {detail} = this;
      if (detail.planUserPhotoResourceUrl && detail.planUserPhotoResourceUrl.length) {
        return detail.planUserPhotoResourceUrl;
      }
      if (detail.creator) {
        return detail.creator.avatar;
      }
    },


    nickName() {
      const {detail} = this;
      if (detail.planUsername && detail.planUsername.length) {
        return detail.planUsername;
      }
      if (detail.creator) {
        return detail.creator.nickName;
      }
    },

    innerVipRightId() {
      if (this.vipRightId) {
        return this.vipRightId;
      }

      let vipRightName;
      if (this.detail.creator) {
        vipRightName = this.detail.creator.vipTypeName
      } else {
        vipRightName = this.vipRightName
      }

      switch (vipRightName) {
        case '试用会员':
          return 1;
        case '入门会员':
          return 2;
        case '超级VIP会员':
        case '超级会员':
          return 4;
        case '俱乐部会员':
          return 5;
        case '注册会员':
        default:
          return 3;
      }
    }

  },


  async created() {
    await this.$store.dispatch('config/loadConfig')
    this.visibleOption = this.$store.getters['config/visibleOption']
  },


  methods: {

    async toggleCollect() {
      const detail = this.detail
      await planV2Api.toggleCollect({planId: detail.id || detail.plan.id})
      const currHasCollect = detail.hasCollect

      let delta = 0
      if (currHasCollect) {
        detail.hasCollect = false
        delta = -1
        await sleep(500)
        this.$myNotify.show({
          type: 'info',
          text: '取消收藏成功',
        })
      } else {
        await sleep(500)
        detail.hasCollect = true
        delta = 1
        this.$myNotify.show({
          type: 'success',
          text: '收藏成功',
          rightText:'去看看',
          onClick:()=>{
            this.$router.push('/collection?refresh')
          }
        })
      }
      if(detail.collectAmount){
        detail.collectAmount+=delta
        detail.collectAmount = Math.max(detail.collectAmount,0)
      }else{
        detail.plan.collectTimes+=delta
        detail.plan.collectTimes = Math.max(detail.plan.collectTimes,0)
      }

      this.$emit('update:detail',detail)
    },


    setTagClass() {
      let type;
      if (this.detail.planCategory) {
        type = this.detail.planCategory;
      } else if (this.detail.plan) {
        type = this.detail.plan.freeType;
      }
      // console.log(type);

      switch (type) {
        case "会员免费":
        case 2:
          this.detail.planCategory = '会员免费';
          return 'vip-free';
        case "会员折扣":
        case 3:
          this.detail.planCategory = '会员折扣';
          return 'no-free';
        case "免费方案":
        case 1:
          this.detail.planCategory = '免费方案';
          return 'free';
      }
    },


    handleClick(e) {
      const detail = this.detail.plan ? this.detail.plan : this.detail;
      if (!this.search) {
        return this.$emit('handle-click', detail);
      }
      if (e.target.className === 'one-tag') {
        return
      }
      this.$emit('handle-click', detail);
    },


    goUserHome() {
      let createId;
      if (this.detail.creator) {
        createId = this.detail.creator.id;
      } else {
        createId = this.detail.createId;
      }
      window.location.href = `/otherPerson/${createId}`;
    },

    //根据tag搜索
    searchByTag(tag) {
      //if(this.group) return;
      if (this.stopTagClick) {
        return this.$emit('clickTag', tag)
      }
      let url = '';
      if (tag) {
        url = `${window.location.origin}/home?keyword=${tag}`;
      } else {
        if (this.keyword) {
          url = `${window.location.origin}/home?keyword=${this.keyword}`;
        } else {
          url = `${window.location.origin}/home`;
        }
      }
      window.location.replace(url);
    }
  }
}
</script>

<style scoped lang="less">
.case-item {
  background-color: #fff;
  border-radius: 6px;
  position: relative;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
}

.case-item:nth-child(n+2) {
  margin-top: 20px;
}

.case-tag {
  position: absolute;
  right: 10px;
  top: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 22px;
  font-size: 12px;
  padding: 0 8px;
  height: 24px;
  z-index: 8;

  &.vip-free {
    background-color: rgba(255, 241, 198, 1);
    color: var(--text-color);
  }

  &.no-free {
    background-color: var(--text-color);
    color: #fff;
  }

  &.free {
    background-color: #808080;
    color: #fff;
  }
}

.case-item .cover {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  min-width: calc(100vw - (28px * 2));
  max-width: calc(100vw - (28px * 2));
  min-height: calc((100vw - (28px * 2)) * 9 / 16);
  max-height: calc((100vw - (28px * 2)) * 9 / 16);
}

.case-item .cover-div {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  min-width: calc(100vw - (28px * 2));
  //max-width: calc(100vw - (28px * 2));
  min-height: calc((100vw - (28px * 2)) * 9 / 16);
  //max-height: calc((100vw - (28px * 2)) * 9 / 16);
  background-size: 100% 100%;
  background-position: center;
  overflow-y: hidden;

  display: flex;
  align-items: center;
  justify-content: center;
  color: #C0C4CC;
  background-color: #f2f5f7;
}

.case-item .title-group {
  padding: 5px 27px 5px 14px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.case-item .title-group .title-span {
  font-size: 14px;
  font-weight: bold;
  color: var(--text-color);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  flex-shrink: 0;
  width: 312px;

  &.title-span-is-recommended {
    width: 270px;
  }
}

.tags {
  padding: 5px 27px 5px 14px;
  height: 20px;
  display: flex;
  width: calc(100vw - (56px * 2));
}

.group {
  padding-bottom: 18px;
}

.tags span {
  float: left;
  box-sizing: border-box;
  padding: 0 5px;
  min-width: 50px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  background-color: var(--grey-bg);
  color: var(--grey-color);
  font-size: 12px;
  border-radius: 19px;
  margin-right: 10px;
  margin-bottom: 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: calc((100vw - (56px * 2)) / 3);;
}

.creator-group {
  margin-top: 5px;
  padding: 8px 27px 8px 14px;
  border-top: 1px solid rgba(21, 29, 54, 0.03);
  display: flex;
  align-items: center;
}

.creator-group .avatar {
  width: 20px;
  height: 20px;
  border-radius: 100%;
}

.creator-group .content {
  color: #999;
  font-size: 12px;
  margin-left: 4px;
  margin-right: 4px;
}

.data {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
}

.dynamic {
  display: flex;
  align-items: center;
}

.dynamic-item {
  display: flex;
  align-items: center;
  margin-right: 12px;
  font-size: 10px;
  color: #ccc;
}

.dynamic-item-value {
  font-size: 12px;
  margin-left: 2px;
}

.dynamic-item .eye-icon {
  font-size: 20px;
  transform: translateY(3px);
}

.info {
  font-size: 12px;
}

.self .data {
  padding-bottom: 12px;
}
</style>
