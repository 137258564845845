export function scrollToDropdownMenu(offsetTop = 0, homeClass = '.home') {
  const $link = document.querySelector('.home-quick-link')
  const $banner = document.querySelector('.home-banner')
  const $home = document.querySelector(homeClass)
  let deltaTop = 0
  if ($link) {
    deltaTop += $link.getBoundingClientRect().height
  }
  if ($banner) {
    deltaTop += $banner.getBoundingClientRect().height
  }

  const top = offsetTop + deltaTop
  $home.scrollTo({top, behavior: 'smooth'})
}
