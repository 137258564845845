import {v2Request} from "./v2Request";


export const searchHistoryV2Api = {

  async add(keyword, targetType) {
    v2Request.get('searchHistory/add', {
      params: {targetType, keyword}
    })
  },


  async findMy() {
    return v2Request.get('searchHistory/findMy')
  },

  async findMyHistoryKeywords() {
    return v2Request.post('search-svr/searchRecord/findMyHistoryKeywords')
  },


  async deleteMy(target) {
    return v2Request.post('search-svr/searchRecord/deleteMyHistoryKeywords', {target})
  }

}
